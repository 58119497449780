import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Download from '@mui/icons-material/Download';
// import Edit from '@mui/icons-material/Edit';
import { MultiColumnList } from '../../components/MultiColumnList';
import Collapse from '@mui/material/Collapse';
import { useTypedSelector } from '@fiji/common/src/app/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTrends } from 'hooks/useGetTrends';
import { useIsMount } from '@fiji/common/src/hooks';
import { getRandomColor, getTimeInFromTo } from '@fiji/common/src/utils/helpers';
import { useDispatch } from 'react-redux';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import { useGetChannelUnitsQuery, useGetDeviceByIdQuery } from '@fiji/common/src/features/deviceManagement/deviceApi';
import { selectedOrg } from '@fiji/common/src/features/orgManagement/orgSlice';

import { setPredefinedTrends } from '@fiji/common/src/features/widgetManagement/trendsWidgetSlice';

import { CustomModal, CustomTransComponent, Loader } from 'components';
import { StyledMenu } from 'pages/Chart/StyledMenu';
import { ExportModal } from 'pages/common/ExportModal';

import { subscribeTopic } from '@fiji/common/src/mqtt_connection/mqttConnection';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import HighchartsBoost from 'highcharts/modules/boost';
import moment from 'moment';
import { CHART_TYPE, TREND_INITIAL_CONFIG } from 'pages/WidgetManagement/common';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { seriesColors } from '@fiji/common';
import { useTransformData } from 'hooks/useTransformData';
import { useGetUserProfileQuery } from '@fiji/common/src/features/profile/profileApi';
import { UserProfile } from '@fiji/common/src/types';
import { ApiEndpointType, ApiResponseType } from '@fiji/common/src/features/common/commonTypes';
import { setMessageContent } from '@fiji/common/src/features/common/commonSlice';

HighchartsBoost(Highcharts);

export type TelemetryChartProps = {
    type?: 'area' | 'line';
    widgetTypeId?: string;
    route?: string;
    state?: string;
    maxCount?: number;
    secondary: any[];
    widgetName?: string;
    [key: string]: any;
};

export type LegendData = {
    color: string;
    name: string;
    visible: boolean;
    channelId: string;
};

const timeBtn = [
    {
        btnName: '24 Hours',
        btnValue: 24,
    },
    {
        btnName: '15 Days',
        btnValue: 360,
    },
    {
        btnName: '31 Days',
        btnValue: 744,
    },
];

export const Trends = (): JSX.Element => {
    const theme: any = useTheme();
    const { deviceId } = useParams();
    const [isCalender, setIsCalender] = React.useState(false);
    const [isRefreshed, setIsRefreshed] = React.useState(false);
    const isMount = useIsMount();
    const navigate = useNavigate();
    const currentRealmName = useTypedSelector((state: any) => state?.common?.selectedRealm);
    const dispatch = useDispatch();
    const currentOrg = useTypedSelector(selectedOrg);
    const [openSideBar, setOpenSideBar] = React.useState(false);
    const [date, setDate]: any = useState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popUp, setPopUp] = React.useState(false);
    const [timeRange, setTimeRange] = React.useState<{ from: any; to: any }>({
        ...getTimeInFromTo(24, 'h'),
    });
    const [selectedUnits, setSelectedUnits] = React.useState<any>([]);
    const [colors, setColors] = React.useState<any>(seriesColors);
    const modalRef: any = React.useRef(null);
    const confirmModalRef: any = React.useRef(null);
    const mqttConnectionStatus = useTypedSelector((state) => state['common']['mqttConnectionStatus']);

    const { data: profileData } = useGetUserProfileQuery({}, { refetchOnMountOrArgChange: true }) as ApiEndpointType<
        ApiResponseType<UserProfile>
    >;
    const open = Boolean(anchorEl);

    const { data: deviceData }: any = useGetDeviceByIdQuery(deviceId, { skip: !currentOrg?.id });
    const { pointConversion, timezoneOffset } = useTransformData();

    const {
        trends: widgetData,
        allChannels: channelData,
        isLoading,
        payloadChangeHandler,
        payload,
        isError,
    } = useGetTrends({
        sourceId: deviceData?.data?.deviceTypeId,
        deviceId: deviceId,
        widgetType: 'trends',
    });
    const { data: unitList } = useGetChannelUnitsQuery(payload?.payload, { skip: !payload?.payload });

    const [legendData, setLegendData] = useState<LegendData[]>(
        channelData?.data?.map((item: any, index: number) => ({
            channelId: item?.channelId,
            color: item?.color ?? getRandomColor(),
            visible: index < 5 ? true : false,
            name: item?.displayName,
        })) ?? []
    );
    const [channelIds, setChannelIds] = useState([]);
    const [activeDuration, setActiveDuration] = useState<number>(24);
    const [isTimeRangeActive, setIsTimeRangeActive] = useState(false);
    const chartRef: any = useRef(null);

    const [options, setOptions] = useState<any>(TREND_INITIAL_CONFIG);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };
    const handleSideBar = (): void => {
        setOpenSideBar((prev) => !prev);
    };

    React.useEffect(() => {
        if (!isMount && channelIds) {
            setIsRefreshed(false);
            if (!isRefreshed)
                payloadChangeHandler?.(
                    channelIds,
                    date?.[0]
                        ? {
                              from: new Date(date[0])?.getTime(),
                              to: new Date(date[1])?.getTime(),
                          }
                        : timeRange,
                    false
                );
        }
    }, [channelIds]);

    useEffect(() => {
        if (channelData) {
            setIsRefreshed(true);
            const newColors = JSON.parse(JSON.stringify(colors));
            setLegendData(
                channelData?.data?.map((item: any, index: number) => {
                    let color: string = getRandomColor();
                    if (index < 5) {
                        color = newColors[0];
                        newColors.shift();
                    }
                    return {
                        channelId: item?.channelId,
                        color: color,
                        visible: index < 5 ? true : false,
                        name: item?.displayName,
                    };
                })
            );
            setColors(newColors);
            setChannelIds(channelData?.data?.slice(0, 5).map((item: any) => item?.channelId));
        }
    }, [channelData]);

    const activeDurationHandler = (hour: number): void => {
        const time = getTimeInFromTo(hour, 'h');
        if (hour > 24) {
            setIsTimeRangeActive(true);
            setDate([moment(time?.from) ?? null, moment(time?.to) ?? null]);
        } else {
            setIsTimeRangeActive(false);
            setDate([null, null]);
        }
        setActiveDuration(hour);
        setTimeRange(time);
    };

    const dispatchHandler = React.useCallback(() => {
        if (legendData?.length)
            dispatch(
                setPredefinedTrends({
                    secondary: channelData?.data
                        ?.filter(
                            (item: any) => legendData?.find((item2) => item2?.channelId === item?.channelId)?.visible
                        )
                        ?.map((item: any) => ({
                            ...item,
                            id: `${item.channelId}${deviceId}`,
                            devicePath: deviceData?.data?.groupPath,
                            deviceId: deviceId,
                            deviceName: deviceData?.data?.name,
                        })),
                    multiDevice: true,
                })
            );
    }, [legendData]);

    const dateChangeHandler = (): void => {
        if (!isMount && channelData && date?.[0] && date?.[1]) {
            payloadChangeHandler(channelIds, activeDuration, true, {
                from: new Date(date?.[0])?.getTime() === 0 ? null : new Date(date?.[0])?.getTime(),
                to: new Date(date?.[1])?.getTime() === 0 ? null : new Date(date?.[1])?.getTime(),
            });
        }
    };

    const getSeriesData = (data: any): any[] => {
        const unitCount: any = {};

        return data?.map((trend: any, index: number) => {
            const unit = unitCount[trend?.unit ?? 'none'];
            if (isNaN(unit)) unitCount[trend?.unit ?? 'none'] = index;

            return {
                boostThreshold: 2000,
                id: trend?.channelId,
                name: channelData?.data?.find((channel: any): any => channel?.channelId === trend?.channelId)
                    ?.displayName,
                data: trend?.data?.map((point: any): any => [point?.x, point?.y]) ?? [],
                color: legendData?.find((item: any) => item.channelId === trend?.channelId)?.color,
                type: 'line',
                tooltip: {
                    valueSuffix: trend?.unit ? ` ${trend?.unit}` : '',
                },
                yAxis: unitCount[trend?.unit ?? 'none'],
            };
        });
    };

    const getYAxis = (data: any): any =>
        data.map((trend: any): any => ({
            title: {
                text: '',
            },
            labels: {
                formatter: function (): any {
                    return `${pointConversion((this as any).value)}${trend?.unit ? ` ${trend?.unit}` : ''}`;
                },
            },
        }));

    const addPoints = (message: any): void => {
        const current: any = chartRef?.current;
        const seriesIndex = current?.chart?.series.findIndex((s: any) => s?.userOptions?.id === message.channelId);
        if (seriesIndex !== -1) {
            current?.chart?.series?.[seriesIndex]?.addPoint([message.timestamp, +message.value], true, false);
            if (
                message?.unit &&
                current?.chart?.series?.[seriesIndex]?.tooltipOptions?.valueSuffix !== ` ${message?.unit}`
            ) {
                current?.chart?.series?.[seriesIndex]?.update({
                    tooltip: { valueSuffix: ` ${message?.unit}` },
                });
                current?.chart?.yAxis[seriesIndex].update({
                    title: {
                        text: '',
                    },
                    labels: {
                        formatter: function (): any {
                            return `${(this as any).value}${message?.unit ?? ''}`;
                        },
                    },
                });
            }
            current?.chart?.hideLoading();
        }
    };

    const mqttResponseHandler = (message: any): void => {
        addPoints({
            timestamp: message?.timestamp,
            channelId: message?.key,
            deviceId: message?.deviceId,
            unit: message?.unit,
            value: +message?.value,
        });
    };

    React.useEffect(() => {
        if (mqttConnectionStatus === true && profileData?.data && deviceData?.data) {
            subscribeTopic(
                `BSSRM/TREND/${profileData?.data?.currentRealmId}/${currentOrg?.id}/${deviceData?.data?.groupId}/${deviceId}/devices`,
                mqttResponseHandler
            );
        }
    }, [mqttConnectionStatus, profileData?.data, deviceData?.data]);

    useEffect(() => {
        const chart = chartRef?.current?.chart;
        const newOption = JSON.parse(JSON.stringify(options));
        let data: any = JSON.parse(JSON.stringify(widgetData?.secondary ?? []));
        if (selectedUnits?.length && selectedUnits?.length !== unitList?.data?.length) {
            data = data?.filter((trend: any) => selectedUnits?.includes(trend?.unit));
        }
        if (
            chart &&
            widgetData?.secondary?.length &&
            channelData?.data &&
            !isError &&
            !isLoading &&
            data?.some((item: any) => Boolean(item?.data?.length))
        ) {
            chart?.hideLoading();
            newOption['yAxis'] = getYAxis(data);
            newOption['series'] = getSeriesData(data);
            newOption['chart'] = {
                zoomType: 'x',
                zooming: {
                    mouseWheel: {
                        enabled: true,
                        sensitivity: 1.1,
                        type: 'x',
                    },
                },
            };
            setOptions(newOption);
        } else if (Object.keys(chart ?? {})?.length) {
            newOption['series'] = [];
            setOptions(newOption);
            chart?.showLoading('No Trends Data Available');
        }
        return () => {
            newOption['series'] = [];
            setOptions(newOption);
        };
    }, [widgetData, channelData, isError, isLoading, selectedUnits]);

    const changeHandler = (e: any, id: number): void => {
        const newLegendData = JSON.parse(JSON.stringify(legendData));
        const newColors = JSON.parse(JSON.stringify(colors));
        const index = newLegendData?.findIndex((legend: any): any => legend.channelId === id);
        // if (!e.target.checked && channelIds?.length <= 1) {
        //     dispatch(setMessageContent({ isOpen: true, message: 'Minimum 1 channel is required to see trends.' }));
        //     return;
        // }
        if (e.target.checked && channelIds?.length >= 10) {
            dispatch(setMessageContent({ isOpen: true, message: 'Maximum limit of 10 channels reached.' }));

            return;
        }
        if (index > -1) {
            newLegendData[index].visible = e.target.checked;
            if (e.target.checked) {
                newLegendData[index].color = newColors[0];
                newColors?.shift();
            } else {
                newColors.unshift(newLegendData[index].color);
            }
        }
        setColors(newColors);
        const newChannelIds: any = [];
        newLegendData.map((legend: any) => {
            if (legend.visible) {
                newChannelIds.push(legend.channelId);
            }
        });
        setChannelIds(newChannelIds);
        setLegendData(newLegendData);
        const current: any = chartRef?.current;
        if (current?.chart?.series?.length > 0) current?.chart.series.forEach((series: any) => series.remove());
    };

    const advanceAction = (fileType: any): void => {
        navigate(`/${currentRealmName}/advanceExportTrends`, {
            state: { activeTab: 'Trends', fileType: fileType },
        });
        dispatchHandler();
    };

    const changeUnitHandler = (e: any, value: string): void => {
        if (selectedUnits?.includes(value)) {
            setSelectedUnits(selectedUnits?.filter((item: string) => item !== value));
        } else setSelectedUnits((prev: any) => [...prev, value]);
    };

    React.useEffect(() => {
        if (!isMount && widgetData) {
            payloadChangeHandler?.(channelIds, timeRange, false);
        }
    }, [timeRange]);

    useEffect(() => {
        if (!isNaN(timezoneOffset)) {
            Highcharts.setOptions({
                time: {
                    timezoneOffset: timezoneOffset,
                },
                lang: {
                    thousandsSep: '',
                },
            });
        }
    }, [timezoneOffset]);

    return (
        <>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                className="w-100 bg-white trends-wrapper"
                spacing={2}
            >
                <Stack className={`${openSideBar ? 'w-80' : 'w-100'}`}>
                    {/* Header */}
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} m={3}>
                        <Stack direction={'row'} alignItems={'center'} spacing={8}>
                            <Stack direction={'row'} alignItems={'center'} spacing={1} px={1}>
                                <IconButton
                                    disabled={!channelIds?.length}
                                    onClick={(): void => {
                                        if (isTimeRangeActive) {
                                            payloadChangeHandler(channelIds, activeDuration, true, {
                                                from: date?.[0] ? new Date(date[0])?.getTime() - 86400000 : null,
                                                to: date?.[1] ? new Date(date[1])?.getTime() - 86400000 : null,
                                            });
                                            setDate([
                                                date?.[0] ? moment(new Date(date[0])?.getTime() - 86400000) : null,
                                                date?.[1] ? moment(new Date(date[1])?.getTime() - 86400000) : null,
                                            ]);
                                        } else
                                            setTimeRange((prop: { from: number; to: number }) => ({
                                                from: (prop?.from ?? 0) - 86400000,
                                                to: (prop?.to ?? 0) - 86400000,
                                            }));
                                    }}
                                >
                                    <ArrowBackIos className="height-16 width-16" color="primary" />
                                </IconButton>

                                <FormControl fullWidth sx={{ minWidth: 160 }}>
                                    <Select
                                        value={moment(
                                            isTimeRangeActive ? date?.[0] : timeRange?.to ?? Date?.now()
                                        ).format('MMM D, YYYY')}
                                        renderValue={() =>
                                            moment(isTimeRangeActive ? date?.[0] : timeRange?.to ?? Date?.now()).format(
                                                'MMM D, YYYY'
                                            )
                                        }
                                        variant="outlined"
                                        onOpen={(): void => setIsCalender(true)}
                                        open={isCalender}
                                        inputProps={{ IconComponent: () => null }}
                                        disabled={!channelIds?.length}
                                        // onClose={(): void => setIsCalender(false)}
                                    >
                                        <Stack justifyContent={'center'} alignSelf={'center'}>
                                            <Stack
                                                justifyContent={'center'}
                                                alignContent={'center'}
                                                display={'flex'}
                                                flexDirection={'row'}
                                                m={1}
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    value={moment(date?.[0] ?? new Date().setHours(0, 0, 0, 0)).format(
                                                        'MMM D, YYYY'
                                                    )}
                                                    label=""
                                                    variant="outlined"
                                                    inputProps={{ readOnly: true }}
                                                    sx={{ padding: '12px 2px' }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{ alignSelf: 'center', fontWeight: 400, margin: '8px' }}
                                                >
                                                    <CustomTransComponent translationKey={'COMMON:TO'} />
                                                </Typography>
                                                <TextField
                                                    id="outlined-basic"
                                                    value={moment(
                                                        date?.[1] ?? new Date().setHours(23, 59, 59, 0)
                                                    ).format('MMM D, YYYY')}
                                                    label=""
                                                    variant="outlined"
                                                    inputProps={{ readOnly: true }}
                                                    sx={{ padding: '12px 2px' }}
                                                />
                                            </Stack>
                                            <Stack
                                                sx={{
                                                    '& .MuiDateRangeCalendar-root': { alignSelf: 'center' },
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <StaticDateRangePicker
                                                        disableFuture={true}
                                                        value={date ?? [null, null]}
                                                        onChange={(dateData: any): void => {
                                                            setDate([dateData[0], dateData[1]?.endOf?.('day') ?? null]);
                                                        }}
                                                        // calendars={1}
                                                        slotProps={{
                                                            actionBar: { actions: [] },
                                                            toolbar: { hidden: true },
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                            <Stack
                                                justifyContent={'flex-end'}
                                                alignContent={'center'}
                                                display={'flex'}
                                                flexDirection={'row'}
                                                m={2}
                                            >
                                                <Button
                                                    sx={{
                                                        marginRight: '8px',
                                                        border: `1px solid ${theme?.palette?.primary?.main}`,
                                                        '&:hover': {
                                                            backgroundColor: theme?.palette?.primary?.[50],
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: theme?.palette?.primary?.[50],
                                                        },
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={(): void => setIsCalender(false)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    disabled={!date?.[0] || !date?.[1]}
                                                    onClick={(): void => {
                                                        dateChangeHandler();
                                                        setIsCalender(false);
                                                        setIsTimeRangeActive(true);
                                                        setActiveDuration(0);
                                                    }}
                                                    sx={{
                                                        backgroundColor: theme?.palette?.primary?.main,
                                                        '&:hover': {
                                                            backgroundColor: theme?.palette?.primary?.main,
                                                        },
                                                        '&.Mui-disabled': {
                                                            color: theme?.palette?.primary?.[200],
                                                            backgroundColor: theme?.palette?.primary?.[50],
                                                        },
                                                    }}
                                                >
                                                    Apply
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Select>
                                </FormControl>
                                {isTimeRangeActive && (
                                    <>
                                        {' '}
                                        <Typography>To</Typography>
                                        <FormControl fullWidth sx={{ minWidth: 160 }}>
                                            <Select
                                                value={moment(timeRange?.from ?? null).format('MMM D, YYYY')}
                                                renderValue={() => moment(date?.[1] ?? null).format('MMM D, YYYY')}
                                                variant="outlined"
                                                onOpen={(): void => setIsCalender(true)}
                                                inputProps={{ IconComponent: () => null }}
                                                disabled={!channelIds?.length}
                                                // onClose={(): void => setIsCalender(false)}
                                            ></Select>
                                        </FormControl>
                                    </>
                                )}
                                <IconButton
                                    disabled={
                                        (isTimeRangeActive
                                            ? moment(moment().format('MM/DD/YY')).isSameOrBefore(
                                                  moment(new Date(date[1] ?? 0)?.getTime() ?? moment()).format(
                                                      'MM/DD/YY'
                                                  )
                                              )
                                            : moment(moment().format('MM/DD/YY')).isSameOrBefore(
                                                  moment(timeRange?.to ?? moment()).format('MM/DD/YY')
                                              )) || !channelIds?.length
                                    }
                                    onClick={(): void => {
                                        if (isTimeRangeActive) {
                                            payloadChangeHandler(channelIds, activeDuration, true, {
                                                from: date?.[0] ? new Date(date[0])?.getTime() + 86400000 : null,
                                                to: date?.[1] ? new Date(date[1])?.getTime() + 86400000 : null,
                                            });
                                            setDate([
                                                date?.[0] ? moment(new Date(date[0])?.getTime() + 86400000) : null,
                                                date?.[1] ? moment(new Date(date[1])?.getTime() + 86400000) : null,
                                            ]);
                                        } else
                                            setTimeRange((prop: { from: number; to: number }) => ({
                                                from: (prop?.from ?? 0) + 86400000,
                                                to: (prop?.to ?? 0) + 86400000,
                                            }));
                                    }}
                                >
                                    <ArrowForwardIos
                                        className="height-16 width-16"
                                        {...(!(isTimeRangeActive
                                            ? moment(moment().format('MM/DD/YY')).isSameOrBefore(
                                                  moment(new Date(date[1] ?? 0)?.getTime() ?? moment()).format(
                                                      'MM/DD/YY'
                                                  )
                                              )
                                            : moment(moment().format('MM/DD/YY')).isSameOrBefore(
                                                  moment(timeRange?.to ?? moment()).format('MM/DD/YY')
                                              )) && {
                                            color: 'primary',
                                        })}
                                    />
                                </IconButton>
                            </Stack>
                            <Stack direction={'row'} alignItems={'center'}>
                                {timeBtn?.map(
                                    (btn: { btnName: string; btnValue: number }): JSX.Element => (
                                        <Button
                                            key={btn?.btnValue}
                                            disabled={!channelIds?.length}
                                            id={`${btn?.btnName}`}
                                            variant={activeDuration === btn?.btnValue ? 'contained' : 'outlined'}
                                            sx={{
                                                borderRadius: '0',
                                                backgroundColor:
                                                    activeDuration === btn?.btnValue
                                                        ? theme?.palette?.primary?.main
                                                        : '',
                                                border: `${
                                                    activeDuration === btn?.btnValue
                                                        ? 0
                                                        : `1px solid ${theme?.palette?.primary?.main}`
                                                }`,
                                                '&:hover': {
                                                    backgroundColor:
                                                        activeDuration === btn?.btnValue
                                                            ? theme?.palette?.primary?.main
                                                            : theme?.palette?.primary?.[50],
                                                },
                                            }}
                                            onClick={(): void => activeDurationHandler(btn?.btnValue)}
                                        >
                                            {btn?.btnName}
                                        </Button>
                                    )
                                )}
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} alignItems={'center'} spacing={4}>
                            <Stack pb={2}>
                                <Box mr={2} sx={{ display: 'flex', justifyContent: 'end' }}>
                                    <Button
                                        id="units"
                                        aria-controls={open ? 'demo-customized-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        sx={{
                                            backgroundColor: 'transparent',
                                            color: '#424E54',
                                        }}
                                        onClick={handleClick}
                                        endIcon={!open ? <ArrowDropDown /> : <ArrowDropUp />}
                                    >
                                        <CustomTransComponent translationKey={'COMMON:UNITS'} />
                                    </Button>
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem className="w-100" key="all">
                                            <Checkbox
                                                id="select-all"
                                                className="margin-right-16"
                                                indeterminate={
                                                    Boolean(selectedUnits.length) &&
                                                    selectedUnits.length < unitList?.data?.length
                                                }
                                                checked={selectedUnits.length === unitList?.data?.length}
                                                onChange={(e: any): void => {
                                                    if (!e?.target?.checked) {
                                                        setSelectedUnits([]);
                                                    } else {
                                                        setSelectedUnits(unitList?.data);
                                                    }
                                                }}
                                            />
                                            <CustomTransComponent translationKey={'COMMON:SELECT_ALL'} />
                                        </MenuItem>
                                        <Divider />
                                        {unitList?.data?.map((item: any): any => (
                                            <MenuItem key={item} value={item} className="w-100">
                                                <Checkbox
                                                    className="margin-right-16"
                                                    checked={selectedUnits?.includes(item)}
                                                    onChange={(e): void => changeUnitHandler(e, item)}
                                                />
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </StyledMenu>
                                </Box>
                            </Stack>
                            <Button
                                id="export"
                                sx={{
                                    border: `1px solid ${theme?.palette?.primary?.main}`,
                                    '&:hover': {
                                        backgroundColor: theme?.palette?.primary?.[50],
                                    },
                                }}
                                variant="outlined"
                                color="primary"
                                startIcon={<Download />}
                                onClick={() =>
                                    modalRef?.current?.onClick({ ...payload, chartType: CHART_TYPE['line'] })
                                }
                            >
                                <CustomTransComponent translationKey={'COMMON:EXPORT'} />
                            </Button>
                        </Stack>
                    </Stack>

                    {/* Chart */}
                    <Stack pt={4} pl={4} sx={{ height: 'calc(100vh - 430px)' }} className="trends-chart-wrapper">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <HighchartsReact
                                containerProps={{ style: { height: 'calc(100vh - 200px)' } }}
                                highcharts={Highcharts}
                                options={options}
                                constructorType="chart"
                                ref={chartRef}
                            />
                        )}
                    </Stack>
                </Stack>

                {/* Sidebar */}
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Box className="bg-white border-left-1" sx={{ height: 'calc(100vh - 324px)' }}>
                        <Collapse orientation="horizontal" in={openSideBar} collapsedSize={70}>
                            <Stack p={2} className="border-bottom-1">
                                {openSideBar ? (
                                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                        <Stack direction={'row'} alignItems={'center'} spacing={4}>
                                            <ChevronRight
                                                id="right"
                                                onClick={handleSideBar}
                                                className="cursor-pointer"
                                            />
                                            <Typography
                                                variant="subtitle2"
                                                fontSize={'14px'}
                                                fontWeight={'600'}
                                                color="primary"
                                            >
                                                <CustomTransComponent translationKey={'COMMON:CHANNELS'} />
                                            </Typography>
                                        </Stack>
                                        {/* <Edit className="color-content" /> */}
                                    </Stack>
                                ) : (
                                    <ChevronLeft id="left" onClick={handleSideBar} className="cursor-pointer" />
                                )}
                            </Stack>
                            <Stack className="height-500 overflow-y-scroll">
                                <MultiColumnList
                                    isLoading={false}
                                    legendData={legendData}
                                    isDisabled={isLoading}
                                    changeHandler={changeHandler}
                                    maxItemsPerColumn={100}
                                />
                            </Stack>
                        </Collapse>
                    </Box>
                </Stack>
            </Stack>
            <CustomModal
                isOpen={popUp}
                type={'small'}
                ref={confirmModalRef}
                header="Channel"
                actions={[
                    {
                        key: 'Ok',
                        label: 'Ok',
                        // close: true,
                        variant: 'contained',
                        handleClick: (): void => setPopUp(false),
                    },
                ]}
                key={'123'}
            >
                <Stack id="alert-dialog-description">
                    <Typography>
                        {channelIds?.length < 10 ? 'Minimum' : 'Maximum'} number of Channels Selected
                    </Typography>
                </Stack>
            </CustomModal>

            <ExportModal
                activeDuration={activeDuration}
                ref={modalRef}
                advanceAction={advanceAction}
                header="Export Trends"
            />
        </>
    );
};
