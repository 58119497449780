import React from 'react';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from '@mui/material';
// import Link from '@mui/material/Link';
import { ChannelData, useSortedData } from '../../../hooks/useSortedData';
import CustomIcon from '../../../components/CustomIcon';

import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Refresh from '@mui/icons-material/Refresh';
import { WidgetFooter } from './WidgetFooter';
import { GroupPath } from 'components';
import { useTransformData } from 'hooks/useTransformData';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { WidgetHeader } from './WidgetHeader';
export const Component = (props: any): JSX.Element => {
    const { sortedData, ids } = useSortedData(props?.widgetData ?? [], ['primary', 'secondary']);
    const { convertDateTime, pointConversion } = useTransformData();
    const componentRef = React.useRef<HTMLDivElement | null>(null);
    const [heroPrimaryCount, setHeroPrimaryCount] = React.useState<number>(sortedData?.primary?.length);
    const handleResizeComponent = (): void => {
        const channelWidth = componentRef.current?.getBoundingClientRect().width ?? sortedData?.primary?.length;
        setHeroPrimaryCount(channelWidth / 120 - ((channelWidth / 120) % 1));
    };
    React.useEffect(() => {
        handleResizeComponent();
        window?.addEventListener('resize', handleResizeComponent);
        componentRef?.current?.addEventListener('resize', handleResizeComponent);
    }, []);
    const renderPrimaryData = (data: any): JSX.Element => (
        <Stack direction={'row'} justifyContent={'space-around'} className="border-bottom-2 padding-y-16">
            {data?.map((item: any) => (
                <Stack
                    key={item?.channelId}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ maxWidth: '120px', width: '100%' }}
                >
                    <CustomIcon
                        iconName={item?.icon?.web?.name}
                        family={item?.icon?.web?.family}
                        iconProps={{ sx: { fontSize: '40px', ...(item?.channelId && { color: '#007BC1' }) } }}
                        defaultIcon={<HelpCenter color="primary" sx={{ fontSize: '40px' }} />}
                    />
                    <Typography variant="body1" fontWeight={'600'} textAlign={'center'}>
                        {item?.value !== 'true' && item?.value !== 'false'
                            ? `${pointConversion(item?.value) || '--'} ${item?.unit ?? '--'}`
                            : item.value}
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight={'400'}
                        fontSize={'14px'}
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            width: '100px',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                        }}
                    >
                        {item?.displayName ?? 'Channel'}
                    </Typography>
                    {props?.widgetData?.multiDevice && (
                        <Typography variant="body2" fontWeight={'300'} fontSize={'14px'}>
                            {item?.deviceName ?? ''}
                        </Typography>
                    )}
                </Stack>
            ))}
        </Stack>
    );

    const renderSecondaryData = (data: any): JSX.Element => {
        if (data?.length) {
            return data.map((item: ChannelData) => (
                <ListItem
                    key={item?.channelId}
                    className="list-item-hover"
                    divider
                    secondaryAction={
                        <Typography variant="body1" fontWeight={'600'}>
                            {item?.value !== 'true' && item?.value !== 'false'
                                ? `${pointConversion(item?.value) || '--'} ${item?.unit ?? '--'}`
                                : item.value}
                        </Typography>
                    }
                >
                    <ListItemButton className="padding-8" role={undefined} dense>
                        <ListItemIcon className="width-24 height-24">
                            <CustomIcon
                                iconName={item?.icon?.web?.name}
                                family={item?.icon?.web?.family}
                                defaultIcon={<HelpCenter color="primary" />}
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1" fontWeight={'600'}>
                                    {item?.displayName ? item?.displayName : 'Secondary...'}
                                </Typography>
                            }
                            secondary={
                                props?.widgetData?.multiDevice && (
                                    <GroupPath
                                        pathName={item?.devicePath ?? []}
                                        deviceName={item?.deviceName}
                                        width="250px"
                                    />
                                )
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ));
        }
        return !sortedData?.primary ? (
            <CustomEmptyState
                icon={<FormatListBulleted fontSize={'inherit'} />}
                title={`No ${props?.widgetData?.widgetTypeId} Found`}
            />
        ) : (
            <></>
        );
    };

    const extraPrimaryCount = (): number => {
        const hero = heroPrimaryCount ?? 0;
        const primary = sortedData?.primary?.length ?? 0;
        if (primary - hero > props?.widgetData?.secondaryChannelCount) {
            return props?.widgetData?.secondaryChannelCount ?? 0;
        }
        return primary > hero ? primary - hero : 0;
    };

    const remainingSecondaryCount = sortedData?.primary?.length
        ? props?.widgetData?.secondaryChannelCount > extraPrimaryCount()
            ? props?.widgetData?.secondaryChannelCount - extraPrimaryCount()
            : 0
        : props?.widgetData?.secondaryChannelCount ?? sortedData?.secondary?.length;
    return (
        <Paper sx={{ height: '100%', overflow: 'auto' }} ref={componentRef}>
            <WidgetHeader
                mode={props?.mode}
                widgetData={props?.widgetData}
                count={(sortedData?.primary?.length ?? 0) + sortedData?.secondary?.length}
                headerAction={
                    !props?.widgetData?.multiDevice &&
                    Object.keys(props?.widgetData ?? {}).includes('primary') && (
                        <Stack
                            direction={'row'}
                            spacing={2}
                            alignItems={'center'}
                            ml={1}
                            position={'absolute'}
                            right={0}
                        >
                            <Typography variant="subtitle2" fontWeight={'300'}>
                                Last Updated:{' '}
                                <b className="fw-600">
                                    {typeof props?.widgetData?.updatedAt !== 'number'
                                        ? props?.widgetData?.updatedAt
                                        : `${convertDateTime({
                                              timestamp: props?.widgetData?.updatedAt ?? Date.now(),
                                          })} ${convertDateTime({
                                              timestamp: props?.widgetData?.updatedAt ?? Date.now(),
                                              customFormat: 'HH:mm',
                                          })}`}
                                </b>
                            </Typography>
                            <IconButton
                                sx={{ margin: '0px !important' }}
                                disabled={!props?.mode?.includes('view')}
                                onClick={props?.refreshCallback}
                            >
                                <Refresh />
                            </IconButton>
                        </Stack>
                    )
                }
            />
            {props?.mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${
                        props?.mode === 'edit' || props?.widgetData?.viewAllButton ? '97px' : '49px'
                    })`,
                    overflow: 'auto',
                }}
            >
                {sortedData?.primary &&
                    (sortedData?.primary?.length > 0 || sortedData?.secondary?.length > 0
                        ? renderPrimaryData(sortedData?.primary.slice(0, heroPrimaryCount))
                        : renderPrimaryData(Array.from({ length: 3 })))}
                {sortedData?.primary?.length > heroPrimaryCount && (
                    <List disablePadding>
                        {renderSecondaryData(
                            sortedData?.primary.slice(heroPrimaryCount, heroPrimaryCount + extraPrimaryCount())
                        )}
                    </List>
                )}
                {Boolean(remainingSecondaryCount) && (
                    <List disablePadding>
                        {sortedData?.secondary?.length > 0 || sortedData?.primary?.length > 0
                            ? renderSecondaryData(sortedData?.secondary?.slice(0, remainingSecondaryCount))
                            : renderSecondaryData(
                                  Array.from({ length: Math.max(props?.widgetData?.secondaryChannelCount ?? 5) })
                              )}
                    </List>
                )}
            </Stack>
            <WidgetFooter
                mode={props?.mode}
                ids={ids}
                widgetData={props?.widgetData}
                disabled={!sortedData?.secondary?.length && !sortedData?.primary?.length}
            />
        </Paper>
    );
};
